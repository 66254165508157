<template>
  <base-select
    v-if="!isLabel"
    :options="optionsAsString"
    :disabled="disabled"
    :icon="icon"
    :placeHolder="placeHolder"
    :value="valueAsString"
    :multiple="multiple"
    :loading="loading"
    :searchable="searchable"
    size="md"
    type="input"
    radio-position="right"
    @update="handleUpdate"
    @open="$emit('open')"
    @close="$emit('close')"
    @input="handleInput"
  ></base-select>
  <base-select-labels
    v-else
    :categories="optionsAsString"
    :disabled="disabled"
    :icon="icon"
    :placeHolder="placeHolder"
    :values="valueAsString"
    :multiple="multiple"
    :loading="loading"
    :searchable="searchable"
    size="md"
    type="input"
    radio-position="right"
    @update="handleUpdate"
    @open="$emit('open')"
    @close="$emit('close')"
    @input="handleInput"
  ></base-select-labels>
</template>
<script>
export default {
  name: 'base-select-wrapper',
  props: {
    options: [],
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Array],
      default: '',
    },
    placeHolder: {
      type: String,
      required: false,
    },
    isLabel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { input: '' };
  },
  computed: {
    optionsAsString() {
      let filteredOptions = [...this.options];
      if (this.input) {
        const inputLower = this.input.toLowerCase();
        filteredOptions = filteredOptions.reduce((acc, val) => {
          if (val.label.toLowerCase().includes(inputLower)) acc.push(val);
          return acc;
        }, []);
      }
      return JSON.stringify(filteredOptions);
    },
    valueAsString() {
      if (typeof this.value === 'string') return this.value;
      return JSON.stringify(this.value);
    },
    computedIcon() {
      if (this.textOnly) {
        return '';
      }
      if (this.icon) {
        return this.icon;
      }
      return this.isHorizontal ? 'ellipsis-h' : 'ellipsis-v';
    },
  },
  methods: {
    handleUpdate(e) {
      const { detail } = e;
      if (!this.multiple) return this.$emit('select', detail[0]);
      return this.$emit('select', Array.from(detail[0]));
    },
    handleInput(e) {
      const { detail } = e;
      if (detail[0] === undefined) return;
      this.input = detail[0];
    },
  },
};
</script>
