import * as types from './mutation-types';

export default {
    async getExportStatus(_, {knowledgeId, type}) {
        if (type) {
            const result = await this.$services.exportClient.getKBExport({
                knowledgeId,
                type,
            });
            return result;
        }
    },

    async requestExport(_, {knowledgeId, type}) {
        if (type) {
            const result = await this.$services.exportClient.putKBExport({
                knowledgeId,
                type,
            });
            return result;
        }
    },

    async getAcademyExportsPredefinedFilters() {
        try {
            const exports =
                await this.$services.exportClient.getExportsPredefinedFilters(
                    'academy_global_attempts',
                );

            return exports;
        } catch (error) {
            return error;
        }
    },

    async getExportsWithPredefinedFilters(_, predefinedFilterId) {
        try {
            const {file} =
                await this.$services.exportClient.getExportGlobalAttempts(
                    predefinedFilterId,
                );

            return file;
        } catch (error) {
            return error;
        }
    },

    // PDF EXPORT
    async getArticlePDFExport({ commit }, { lang, contentId, contentLabel, knowledgeId }) {
        const { path } = this.$router.currentRoute;
        const startingTime = new Date()
        try {
            commit(types.APPEND_PDF_EXPORT, {
                contentId,
                lang,
                contentLabel,
            });
            const {data} =
                await this.$services.exportClient.getArticlePDFExport(lang, contentId);
            if (!data) {
                throw new Error('Failed to fetch article PDF export');
            }
            const blob = new Blob([data], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${contentLabel}.pdf`; // Set the file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            commit(types.UPDATE_PDF_EXPORT, {
                contentId,
                lang,
                display: true,
                type: 'success',
            });
            await this.$services.events.content.exportPDF({
                knowledgeId,
                content: {
                    id: contentId,
                    type: "Article",
                    label: contentLabel,
                    lang,
                },
                export: {
                    generationTime: (new Date().getTime() - startingTime.getTime()) / 1000,
                    type: 'pdf',
                },
            }, path);
        } catch (error) {
            commit(types.UPDATE_PDF_EXPORT, {
                contentId,
                lang,
                display: true,
                type: 'error',
            });
            await this.$services.events.content.exportPDF({
                knowledgeId,
                content: {
                    id: contentId,
                    type: "Article",
                    label: contentLabel,
                    lang,
                },
                export: {
                    generationTime: (new Date().getTime() - startingTime.getTime()) / 1000,
                    type: 'pdf',
                    failed:true,
                    failedReason: error.message,
                },
            }, path);
            return null;
        }
    },

    hidePDFExportToast({commit}, {contentId, lang}) {
        commit(types.DELETE_PDF_EXPORT, {
            contentId,
            lang,
            display: false,
        })
    }
};
