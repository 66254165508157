<template>
  <div>
    <div>
      <div
        v-for="(filter, i) in selectedFilters"
        :key="i"
        class="filter-container"
      >
        <!-- Category operator -->
        <div class="category-operator">
          <base-select-wrapper
            v-if="i === 1"
            :options="operatorOptions"
            :icon="true"
            :value="operator"
            @select="changeCategoryOperator"
          />
          <div v-else>
            {{
              i
                ? $t(
                    `settings.general.collaborative-hub.drawer.content.operator.${operator}`,
                  )
                : $t(
                    `settings.general.collaborative-hub.drawer.content.operator.WHERE`,
                  )
            }}
          </div>
        </div>
        <!-- Filter operator -->
        <div class="filter-wrapper">
          <div class="filter-selector-wrapper">
            <div class="filter-type-wrapper">
              <base-select-wrapper
                :options="filtersOptions"
                :icon="true"
                :value="filter.key"
                @select="handleFilterType($event, filter.key)"
              />
            </div>
            <div class="filter-selector">
              <el-tooltip
                :content="
                  $t(
                    `settings.general.collaborative-hub.drawer.content.operator.${filter.operator}`,
                  )
                "
                effect="dark"
                placement="top"
                popper-class="mb-1"
              >
                <button
                  class="filter-operator"
                  @click="changeFilterOperator(filter)"
                >
                  <font-awesome-icon
                    :icon="[
                      'fal',
                      filter.operator === '$in' ? 'equals' : 'not-equal',
                    ]"
                  />
                </button>
              </el-tooltip>
              <div class="d-flex flex-col w-full">
                <base-select-wrapper
                  class="w-full"
                  :options="filterTypeOptions(filter.key)"
                  :icon="true"
                  :multiple="true"
                  :searchable="true"
                  :value="filter.values"
                  :loading="valuesLoading"
                  @select="handleUpdateFilter($event, filter.key)"
                />
              </div>
            </div>
          </div>
          <button class="delete-button" @click="handleRemoveFilter(filter.key)">
            <font-awesome-icon :icon="['fal', 'trash']" />
          </button>
        </div>
      </div>
    </div>
    <base-button
      variant="tertiary"
      size="sm"
      :disabled="!availableFilters.length"
      @click="handleAddFilter"
      :text="$t('settings.general.collaborative-hub.drawer.content.add-rule')"
      icon="plus"
    >
    </base-button>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  name: 'HubCategoryFilterSearch',
  props: {
    operator: String,
    filters: {
      type: Object,
      default: () => ({}),
    },
    category: String,
  },
  data: () => ({
    usersSearch: [],
    selectedUsers: [],
    usersPage: 1,
    usersTotal: 0,
    lastUserQuery: '',
    popupVisible: false,
    valuesLoading: false,
  }),
  methods: {
    handleAddFilter() {
      let firstUnselectedFilter = 'groups';
      for (const filter of this.filtersOptions) {
        if (!this.filters[filter.key]) {
          firstUnselectedFilter = filter.key;
          break;
        }
      }
      this.$emit('update-filter', {
        path: `${this.category}.${firstUnselectedFilter}`,
        values: [],
        operator: '$in',
      });
    },
    handleFilterType(newType, oldType) {
      this.$emit('update-filter', {
        path: `${this.category}.${newType}`,
        oldPath: `${this.category}.${oldType}`,
        values: [],
        operator: '$in',
      });
    },
    handleRemoveFilter(key) {
      this.$emit('remove-filter', {
        path: `${this.category}.${key}`,
      });
    },
    changeFilterOperator(filter) {
      this.$emit('update-filter', {
        path: `${this.category}.${filter.key}`,
        values: filter.values,
        operator: filter.operator === '$in' ? '$nin' : '$in',
      });
    },
    changeCategoryOperator(operator) {
      this.$emit('update-category', {
        operator,
        key: this.category,
      });
    },
    isFilterSelected(key) {
      return !!this.filters[key];
    },
    filterTypeOptions(key) {
      switch (key) {
        case 'groups':
          return this.groups.map((group) => {
            return { ...group, key: group.id, label: group.name };
          });
        case 'roles':
          return this.roles.map((role) => {
            return { ...role, key: role.id, label: role.name };
          });
        case 'labels':
          return this.userLabelCategories.reduce((acc, category) => {
            acc.push(
              ...category.companyUserLabels.map((label) => {
                return {
                  ...label,
                  key: label.id,
                  label: label.name,
                };
              }),
            );
            return acc;
          }, []);
        case 'ids': {
          const users = [
            ...new Map(
              [
                ...this.usersSearch,
                ...this.selectedUsers.map((user) => ({
                  ...user,
                  hidden: true,
                })),
              ].map((user) => [user.id, user]),
            ).values(),
          ];
          return users.map((user) => {
            return { ...user, key: user.id, label: user.name };
          });
        }
        default:
          return [];
      }
    },
    handleUpdateFilter(value, key) {
      if (key === 'ids') {
        this.selectedUsers = value.map(
          (id) =>
            this.selectedUsers.find((user) => user.id === id) ||
            this.usersSearch.find((user) => user.id === id),
        );
      }
      this.$emit('update-filter', {
        path: `${this.category}.${key}`,
        values: value,
        operator: this.filters[key].operator,
      });
    },
    handleTogglePopup(key, visible) {
      if (key !== 'ids') return;
      this.popupVisible = visible;
    },
    handleScroll() {
      if (
        !this.valuesLoading &&
        this.usersSearch.length < this.usersTotal &&
        this.usersSearch.length > 0 &&
        this.popupVisible
      )
        this.handleSearchUsers('', true);
    },
    handleSearchUsers: debounce(async function (query = '', scroll = false) {
      if (this.valuesLoading) return;

      // Prevent multiple requests for the same query
      if (!scroll) {
        this.lastUserQuery = query;
        this.usersSearch = [];
        this.usersPage = 1;
        this.usersTotal = 0;
      }

      this.valuesLoading = true;
      const results = await this.getEmployees({
        search: scroll ? this.lastUserQuery : query,
        page: this.usersPage,
      });
      if (results.employees) {
        this.usersPage += 1;
        this.usersTotal = results.employees.total;
        this.usersSearch = this.usersSearch.concat(
          results.employees.results.map((user) => ({
            id: user.id,
            name: user.username,
          })),
        );
      }
      this.valuesLoading = false;
    }, 300),
    ...mapActions('adminModule', ['getEmployees', 'getUsersByIds']),
  },
  computed: {
    availableFilters() {
      if (!this.filters) return this.filtersOptions;
      return this.filtersOptions.filter((filter) => !this.filters[filter.key]);
    },
    filtersOptions() {
      return [
        {
          key: 'groups',
          icon: 'users',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.groups',
          ),
        },
        {
          key: 'roles',
          icon: 'medal',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.roles',
          ),
        },
        {
          key: 'labels',
          icon: 'tag',
          label: this.$t(
            'settings.general.collaborative-hub.rows.filters.labels',
          ),
        },
        {
          key: 'ids',
          icon: 'user',
          label: this.$t('settings.general.collaborative-hub.rows.filters.ids'),
        },
      ];
    },
    operatorOptions() {
      return [
        {
          key: 'AND',
          label: this.$t(
            'settings.general.collaborative-hub.drawer.content.operator.AND',
          ),
        },
        {
          key: 'OR',
          label: this.$t(
            'settings.general.collaborative-hub.drawer.content.operator.OR',
          ),
        },
      ];
    },
    selectedFilters() {
      return Object.entries(this.filters)
        .filter(([key]) => key !== 'operator')
        .map(([key, { values, operator }]) => ({
          key,
          label: this.filtersOptions.find((f) => f.key === key).label,
          values,
          operator,
        }));
    },
    ...mapGetters('adminModule', ['groups', 'userLabelCategories', 'roles']),
  },
  async mounted() {
    if (this.filters && this.filters.ids) {
      const data = await this.getUsersByIds(this.filters.ids.values);
      if (data)
        this.selectedUsers = data.map((user) => ({
          id: user.id,
          name: user.username,
        }));
    }
    this.handleSearchUsers('', false);
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  &:deep(.el-input__inner) {
    border-radius: 8px;
    height: 36px;
  }
  &:deep(.el-input__icon) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.category-operator {
  width: 86px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  &:deep(.el-input__inner) {
    padding-right: 16px;
  }
}

.filter-wrapper {
  display: flex;
  align-items: center;
  background-color: $grey-1-mayday;
  padding: 8px;
  width: 100%;
  .delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 14px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    border-radius: 4px;
    margin-left: 8px;
    &:hover {
      color: $red-5-mayday;
      background-color: rgba($red-1-mayday, 0.1);
    }
  }
}

.filter-selector-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
}
.filter-operator {
  cursor: pointer;
  margin-right: 8px;
  width: 36px;
  height: 36px;
  border: 1px solid $grey-4-mayday;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 0;
  font-weight: bold;
  flex: none;
  background-color: white;
  &:hover {
    background-color: $grey-2-mayday;
  }
}

.filter-type-wrapper {
  padding-left: 42px;
  .button-icon {
    height: 100%;
    font-size: 14px;
    color: $grey-8-mayday;
    margin: 0 4px 0 4px;
  }
}
.filter-selector {
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: 100%;
  &:deep(.el-select__input) {
    margin-left: 6px !important;
  }
}

.button-icon {
  margin-right: 4px;
}

.filter-button {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: black;
  font-size: 12px;
}
</style>
