<template>
  <BaseFilter
    :filter="filter"
    :condition-value="conditionValue"
    :remote-value="remoteValue"
    :value="value"
    @reset="$emit('reset', $event)"
    @unselect-all="$emit('unselect-all')"
    @change-additional="$emit('change-additional', $event)"
  >
    <el-checkbox-group v-model="filterValue">
      <el-checkbox
        v-for="(
          { label, fallbackLabel, description, value }, idx
        ) in displayFilters"
        :key="`checkbox-${label}-${idx}`"
        :label="value"
        size="mini"
        class="checkbox-button"
      >
        <span class="d-flex flex-column">
          <span>{{ safeGetLabel(label, fallbackLabel) }}</span>
          <small v-if="description">{{ description }}</small>
        </span>
      </el-checkbox>
    </el-checkbox-group>
  </BaseFilter>
</template>

<script>
import filterFieldMixin from '../../../../mixins/FilterFieldMixin';
export default {
  name: 'checkboxes',
  mixins: [filterFieldMixin],
  methods: {
    safeGetLabel(label, fallbackLabel) {
      return label.startsWith('filters.')
        ? !this.$te(label) && fallbackLabel
          ? fallbackLabel
          : this.$t(label)
        : label;
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox-button {
  border: 1px solid $grey-4-mayday;
  border-radius: 2px;
  padding: 4px 8px;
  &.is-checked {
    border-color: $blue-mayday;
    background-color: rgba($blue-mayday, 0.1);
    :deep() .el-checkbox__inner {
      border-radius: 2px;
      background-color: $blue-mayday;
      box-sizing: border-box;
      box-shadow: $shadow-sm-mayday;
      border: 1px solid $blue-mayday;
      &:after {
        display: none;
      }
    }
  }
}

.el-checkbox {
  margin-left: 0px !important;
  margin-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  :deep() .el-checkbox__label {
    font-size: 12px;
  }
}
</style>
