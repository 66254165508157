<template>
  <div class="full-screen">
    <NavBar
      :hubBadge="backlogTasksCount"
      :notificationBadge="unreadCount"
      :activeSearch="searchModal"
      :activeNotification="notificationPopover"
      :activeDocumentation="documentationPopover"
      @update-language="updateAppLanguage"
      @logout="logout"
      @open-admin-search="searchModal = $event"
      @select-knowledge="updateFocusKnowledge"
      @open-notification-popover="handleOpenNotification"
      @open-documentation-popover="documentationPopover = !documentationPopover"
    />

    <div class="main-content">
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <div class="main-content-wrapper">
          <router-view class="d-none d-lg-block"></router-view>
          <!-- WIDEN WINDOW ALERT -->
          <div
            class="d-lg-none container-fluid widen-window d-flex align-items-center"
          >
            <div class="col-11 text-center px-4">
              <img
                class="enlarge-gif"
                src="~assets/material-design/empty-states/enlarge-screen.gif"
              />
              <p>
                {{ $t('landing.enlarge-window') }}
              </p>
            </div>
          </div>
        </div>
      </fade-transition>
    </div>

    <!-- NAV BAR POPOVERS -->
    <AdminSearchWrapper
      v-if="searchModal"
      @close="searchModal = false"
      :display="true"
    />

    <NotificationPopover
      :visible="notificationPopover"
      @unread-count-update="handleUnreadCountUpdate"
      @close="notificationPopover = false"
    />

    <DocumentationWidget
      ref="documentationWidgetRef"
      :open="documentationPopover"
      :locale="$i18n.locale"
    />
  </div>
</template>

<script>
import AdminSearchWrapper from '@/components/SuperSearch/AdminSearchWrapper';
import { mapActions, mapGetters } from 'vuex';
import NotificationPopover from '@/components/Notifications/NotificationPopover';
import { FadeTransition } from 'vue2-transitions';
import DocumentationWidget from '@/components/DocumentationWidget.vue';
import NavBar from '@/layout/NavBar.vue';

export default {
  components: {
    FadeTransition,
    AdminSearchWrapper,
    NotificationPopover,
    DocumentationWidget,
    NavBar,
  },
  props: {
    autoClose: Boolean,
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'knowledges',
      'focusKnowledgeValue',
      'focusKnowledge',
      'focusKnowledgeDefaultLanguage',
    ]),
    ...mapGetters('taskModule', ['backlogTasksCount']),
  },
  data() {
    return {
      searchModal: false,
      notificationPopover: false,
      documentationPopover: false,
      unreadCount: undefined,
    };
  },
  mounted() {
    this.getKnowledges();
    this.getCompanyHostnames();
    this.getBacklogTasksCount();
  },
  methods: {
    updateAppLanguage(lang) {
      this.$i18n.locale = lang;
      this.$store.dispatch('updateAppLanguage', lang);
      // Set in window for web-components when rendered and eventListener for web-components already rendered
      window.___MAYDAY_CE___.locale = lang;
      window.dispatchEvent(
        new CustomEvent('md-updateAppLanguage', { detail: lang }),
      );
    },
    async updateFocusKnowledge(knowledge) {
      this.setFocusKnowledge(knowledge);
      await this.goToTranslatedEntity({
        entityType: 'Knowledge',
        lang: knowledge.defaultLanguage,
      });
    },
    closeDocumentationPopover() {
      this.$refs.documentationWidgetRef.closeWidget();
    },
    handleUnreadCountUpdate(count) {
      this.unreadCount = count;
    },
    handleOpenNotification() {
      this.notificationPopover = !this.notificationPopover;
      this.documentationPopover = false;
    },
    ...mapActions('knowledgeModule', [
      'setFocusKnowledge',
      'goToTranslatedEntity',
      'getKnowledges',
    ]),
    ...mapActions('adminModule', ['getCompanyHostnames']),
    ...mapActions('taskModule', ['getBacklogTasksCount']),
    ...mapActions(['logout']),
  },
  watch: {
    $route: {
      async handler(newVal) {
        const { params, query, name, matched } = newVal;
        const { lang } = params;

        // Make sure there is always a lang parameter in the url
        if (!lang && matched.some(({ meta }) => meta.isLangRelated)) {
          return this.$router.replace({
            name,
            query,
            params: { ...params, lang: this.focusKnowledgeDefaultLanguage },
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow: hidden;
}

.main-content {
  flex: 1 1 0%;
  max-height: 100vh;
  position: relative;
  overflow: auto;
}

.main-content-wrapper {
  height: 100%;
}

.widen-window {
  height: 100vh;
  width: 100%;
}

.enlarge-gif {
  max-width: 50vw;
}
</style>
