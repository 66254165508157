<template>
  <!-- HEADER -->
  <div class="navigator-header">
    <!-- ROOT LABEL -->
    <text-ellipsis
      tag="h3"
      :text="label"
      class="navigator-header-title"
      @click.native="$emit('focus')"
    />

    <div class="navigator-header-options">
      <TranslationDropdown
        class="mr-1"
        v-if="focusKnowledgeIsMultilinguale"
        :languages="focusKnowledgeLanguages"
        :current-language="editingLanguage"
        tight
        @change-language="switchEditingLanguage($event)"
      />
      <!-- NODE OPTIONS -->
      <AddNodeOptions
        :can-create-content="Boolean(userPermissions.canCreateContent)"
        @add-child="$emit('add-child', $event)"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

import TranslationDropdown from '@/components/TranslationDropdown';
import AddNodeOptions from '../NavigatorNode/CommonNode/CommonNodeOptions/AddNodeOptions';
import TextEllipsis from '@/components/TextEllipsis.vue';

export default {
  name: 'navigator-header',
  components: {
    AddNodeOptions,
    TranslationDropdown,
    TextEllipsis,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    userPermissions: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('knowledgeModule', [
      'focusKnowledgeLanguages',
      'editingLanguage',
      'focusKnowledgeIsMultilinguale',
    ]),
  },
  methods: {
    headerLabelClick() {
      this.$emit('focus');
    },
    ...mapActions('knowledgeModule', ['switchEditingLanguage']),
  },
};
</script>
<style lang="scss" scoped>
.navigator-header {
  padding-left: 8px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigator-header-title {
  font-weight: 700;
  color: black;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  cursor: pointer;
  flex: 1 1 0%;
  margin-right: 8px;
}

.navigator-header-options {
  flex: none;
  display: flex;
  align-items: center;
  z-index: 100;
  height: 24px;
  border-right: 1px solid $grey-2-mayday;
  padding-right: 12px;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>
