<template>
  <div class="automations-main">
    <div>
      <h1>
        {{ $tc('automations.automations', 2) }}
        <span class="mx-3">|</span>
        {{ automations.length }}
      </h1>
    </div>
    <p class="section-description">
      <small>{{ $t('automations.main.description') }}</small>
    </p>

    <div class="divider"></div>

    <div v-if="automations.length > 0">
      <div class="d-flex align-items-center justify-between py-1">
        <div class="label-tag">
          <TextEllipsis :text="label" font-size="14px"/>
        </div>
        <div class="flex-none ml-2">
          <el-input
            :placeholder="$t('automations.main.search')"
            v-model="searchInput"
          ></el-input>
        </div>
      </div>

      <AutomationsMainRow
        :items="getChuckedArray[pagination.currentPage - 1]"
        @open-automation="$emit('open-automation', $event)"
        @automation-delete="$emit('automation-delete', $event)"
      />

      <el-pagination
        class="mt-4 d-flex justify-content-center"
        background
        layout="prev, pager, next"
        :total="getSectionData.length"
        :page-size="pagination.pageSize"
        :current-page.sync="pagination.currentPage"
        hide-on-single-page
      ></el-pagination>
    </div>
    <div v-else class="mt-4 d-flex justify-content-center">
      <h4 class="d-flex align-items-center text-muted">
        <font-awesome-icon :icon="['fad', 'telescope']" class="no-rules-icon" />
        {{ $t('automations.empty-state') }}
      </h4>
    </div>

    <div
      v-if="!getSectionData.length && searchInput.length"
      class="mt-4 d-flex justify-content-center"
    >
      <span
        >Aucun résultat pour la recherche :
        <strong>{{ searchInput }}</strong></span
      >
    </div>
  </div>
</template>

<script>
import AutomationsMainRow from './AutomationsMainRow';
import TextEllipsis from "components/TextEllipsis.vue";

export default {
  name: 'AutomationsMain',
  components: {
    TextEllipsis,
    AutomationsMainRow,
  },
  props: {
    automations: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
    },
  },
  data() {
    return {
      searchInput: '',
      pagination: {
        pageSize: 10,
        currentPage: 0,
      },
    };
  },
  computed: {
    getSectionData() {
      return this.applySearch(this.automations);
    },
    getChuckedArray() {
      return this.chunkArray(this.getSectionData, this.pagination.pageSize);
    },
  },
  methods: {
    chunkArray(array, size) {
      const chunked_arr = [];
      let index = 0;
      while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
      }
      return chunked_arr;
    },
    applySearch(data) {
      const searchInput = this.searchInput.toLowerCase().replace(/\s/g, '');
      if (searchInput === '') return data;
      return data.filter((v) => {
        return v.group.alias.toLowerCase().replace(/\s/g, '').includes(searchInput);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.automations-main {
  width: 100%;
  border: 1px solid $grey-2-mayday;
  border-radius: 8px;
  padding: 24px;
  overflow: hidden;
}

.section-description {
  color: $grey-5-mayday;
}
.divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.no-rules-icon {
  height: 24px;
  width: 24px;
  margin-right: 1em;
}

.label-tag {
  background-color: $blue-0_5-mayday;
  border: 1px solid $blue-5-mayday;
  color: $blue-5-mayday;
  overflow: hidden;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
</style>
